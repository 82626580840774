<template>
  <v-card>
    <DataTablePagination
      :page="page"
      :total-items="totalItems"
      :headers="headers"
      :items="employeeLevels"
      :search="search"
      title="Jenjang Pegawai"
      subtitle="Halaman untuk menambahkan jenjang pegawai pada sekolah"
      add-btn="Tambah Data"
      :total-pages.sync="totalPages"
      @add-item="showFormAdd"
      @edit-item="showFormEdit"
      @delete-item="confirmDestroy"
    >
    </DataTablePagination>
    <ModalDialog
      :visible="modalDialog"
      :width="width"
      @close="modalDialog = false"
    >
      <template
        v-if="dialog == 'add'"
        v-slot:header
      >
        Tambah Jenjang Pegawai
      </template>
      <template
        v-else
        v-slot:header
      >
        Update Jenjang Pegawai
      </template>
      <template v-slot:body>
        <div>
          <v-text-field
            label="Nama Pegawai"
            type="text"
            outlined
            dense
          >
          </v-text-field>
        </div>
      </template>
      <template v-slot:footer>
        <v-btn
          v-if="dialog == 'add'"
          color="primary"
          large
        >
          Tambah
        </v-btn>
        <v-btn
          v-if="dialog == 'edit'"
          color="primary"
          large
        >
          Update
        </v-btn>
        <v-btn
          color="primary"
          outlined
          large
        >
          Batal
        </v-btn>
      </template>
    </ModalDialog>
    <ConfirmDialog
      :visible="confirmDialog"
      @close="confirmDialog = false"
    >
      <template v-slot:header>
        Delete Confirm
      </template>
      <template v-slot:body>
        Are you sure you want to delete this data?
      </template>
      <template v-slot:footer>
        <v-btn
          color="primary"
          outlined
          large
        >
          Cancel
        </v-btn>
        <v-btn
          color="primary"
          large
        >
          Delete
        </v-btn>
      </template>
    </ConfirmDialog>
  </v-card>
</template>

<script>
import DataTablePagination from '../components/DataTablePagination.vue'
import ModalDialog from '../components/ModalDialog.vue'
import ConfirmDialog from '../components/ConfirmDialog.vue'

export default {
  name: 'ReferencyEmployeeLevel',
  components: {
    DataTablePagination,
    ModalDialog,
    ConfirmDialog,
  },
  data() {
    return {
      page: 1,
      modalDialog: false,
      confirmDialog: false,
      width: '600',
      dialog: false,
      totalPages: 0,
      totalItems: 10,
      search: '',
      headers: [
        { text: 'No', value: 'no' },
        { text: 'Jenjang Pegawai', value: 'title' },
        { text: 'Aksi', value: 'actions' },
      ],
      employeeLevels: [
        { no: '#', title: 'Kepala Sekolah' },
        { no: '#', title: 'Guru Walikelas' },
        { no: '#', title: 'Kepala Laboran' },
        { no: '#', title: 'Staf Laboran' },
      ],
    }
  },
  methods: {
    showFormAdd() {
      this.dialog = 'add'
      this.modalDialog = true
    },
    showFormEdit() {
      this.dialog = 'edit'
      this.modalDialog = true
    },
    confirmDestroy() {
      this.confirmDialog = true
    },
  },
}
</script>

<style>
</style>
