<template>
  <v-card>
    <DataTablePagination
      :page="page"
      :total-items="totalItems"
      :headers="headers"
      :items="teacherStatus"
      :search="search"
      title="Status Guru"
      subtitle="Halaman untuk menambahkan status guru pada sekolah"
      add-btn="Tambah Data"
      :total-pages.sync="totalPages"
      @add-item="showFormAdd"
      @edit-item="showFormEdit"
      @delete-item="confirmDestroy"
    >
    </DataTablePagination>
    <ModalDialog
      :visible="modalDialog"
      :width="width"
      @close="modalDialog = false"
    >
      <template
        v-if="dialog == 'add'"
        v-slot:header
      >
        Tambah Status Guru
      </template>
      <template
        v-else
        v-slot:header
      >
        Update Status Guru
      </template>
      <template v-slot:body>
        <div>
          <v-text-field
            label="Status Guru"
            type="text"
            outlined
            dense
          >
          </v-text-field>
        </div>
      </template>
      <template v-slot:footer>
        <v-btn
          v-if="dialog == 'add'"
          color="primary"
          large
        >
          Tambah
        </v-btn>
        <v-btn
          v-if="dialog == 'edit'"
          color="primary"
          large
        >
          Update
        </v-btn>
        <v-btn
          color="primary"
          outlined
          large
        >
          Batal
        </v-btn>
      </template>
    </ModalDialog>
    <ConfirmDialog
      :visible="confirmDialog"
      @close="confirmDialog = false"
    >
      <template v-slot:header>
        Delete Confirm
      </template>
      <template v-slot:body>
        Are you sure you want to delete this data?
      </template>
      <template v-slot:footer>
        <v-btn
          color="primary"
          outlined
          large
        >
          Cancel
        </v-btn>
        <v-btn
          color="primary"
          large
        >
          Delete
        </v-btn>
      </template>
    </ConfirmDialog>
  </v-card>
</template>

<script>
import DataTablePagination from '../components/DataTablePagination.vue'
import ModalDialog from '../components/ModalDialog.vue'
import ConfirmDialog from '../components/ConfirmDialog.vue'

export default {
  name: 'ReferencyTeacherStatus',
  components: {
    DataTablePagination,
    ModalDialog,
    ConfirmDialog,
  },
  data() {
    return {
      page: 1,
      totalPages: 0,
      totalItems: 0,
      search: '',
      modalDialog: false,
      width: 600,
      dialog: '',
      confirmDialog: false,
      headers: [
        { text: 'No', value: 'no' },
        { text: 'Status Guru', value: 'status' },
        { text: 'Aksi', value: 'actions' },
      ],
      status: '',
      service: 'teacher',
      teacherStatus: [],
    }
  },
  mounted() {
    this.getTeacherStatus()
  },
  methods: {
    async getTeacherStatus(params = {}) {
      await this.$services.ApiServices.list(this.service, { ...params }).then(({ data }) => {
        this.teacherStatus = data.data.forEach(item => {
          this.teacherStatus.push(item.status)
          this.totalItems = data.meta.total
          this.totalPages = data.meta.last_page
        })
      })
    },
    showFormAdd() {
      this.dialog = 'add'
      this.modalDialog = true
    },
    showFormEdit() {
      this.dialog = 'edit'
      this.modalDialog = true
    },
    confirmDestroy() {
      this.confirmDialog = true
    },
  },
}
</script>

<style>
</style>
