<template>
  <v-card>
    <v-tabs
      v-model="tab"
      show-arrows
      fixed-tabs
    >
      <v-tab
        v-for="tabItem in tabs"
        :key="tabItem.icon"
      >
        <v-icon
          size="20"
          class="me-3"
        >
          {{ tabItem.icon }}
        </v-icon>
        <span>{{ tabItem.title }}</span>
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <referency-employee></referency-employee>
      </v-tab-item>
      <v-tab-item>
        <referency-teacher-status></referency-teacher-status>
      </v-tab-item>
      <v-tab-item>
        <referency-employee-level></referency-employee-level>
      </v-tab-item>
      <v-tab-item>
        <referency-class-level></referency-class-level>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import ReferencyEmployee from './ReferencyEmployee.vue'
import ReferencyTeacherStatus from './ReferencyTeacherStatus.vue'
import ReferencyEmployeeLevel from './ReferencyEmployeeLevel.vue'
import ReferencyClassLevel from './ReferencyClassLevel.vue'

export default {
  components: {
    ReferencyEmployee,
    ReferencyTeacherStatus,
    ReferencyEmployeeLevel,
    ReferencyClassLevel,
  },
  data() {
    return {
      tab: '',
      tabs: [
        { title: 'Bagian Pegawai' },
        { title: 'Status Guru' },
        { title: 'Jenjang Pegawai' },
        { title: 'Tingkatan Kelas' },
      ],
    }
  },
}
</script>

<style>
</style>
